
  import { Component, Vue } from 'vue-property-decorator';

  import HipsterInput from '@/components/HipsterInput.vue';
  import HipsterSelector from '@/components/HipsterSelector.vue';
  import HipsterCheckbox from '@/components/HipsterCheckbox.vue';
  import InspiniaGridItem from '@/components/InspiniaGridItem.vue';

  import { SearchBookmakersParams } from '@/types/search';
  import { SimpleTableRowItem, SimpleTableHeaderItem } from '@/types/search';
  import { FETCH_BOOKMAKERS } from '@/store/bookmakers';
  import { Bookmaker } from '@/types/entities';
  import { get } from 'lodash';
  import SearchResultsTable from '@/components/SearchResultsTable.vue';

  @Component({
    components: { SearchResultsTable, HipsterCheckbox, HipsterInput, HipsterSelector, InspiniaGridItem },
    computed: {
      bookmakers() {
        return this.$store.state.bookmakers.bookmakersList.data.map((bookmaker: Bookmaker): SimpleTableRowItem => {
          return {
            to: `/bookmakers/${bookmaker.id}`,
            line: [
              bookmaker.id as any as string,
              bookmaker.name,
              bookmaker.deposit as any as string,
              bookmaker.bonus as any as string,
              bookmaker.guest_clicks_count as any as string,
              bookmaker.user_clicks_count as any as string,
              new Array(bookmaker.rating).fill('☆').join(''),
              bookmaker.is_public ? 'YES' : 'NO',
              bookmaker.is_supported ? 'YES' : 'NO',
            ]
          };
        });
      }
    }
  })
  export default class InsightsList extends Vue {
    public tableHeader: SimpleTableHeaderItem[] = [
      { class: 'col-lg-1', name: 'id' },
      { class: 'col-lg-3', name: 'name' },
      { class: 'col-lg-1', name: 'deposit' },
      { class: 'col-lg-1', name: 'bonus' },
      { class: 'col-lg-1', name: 'guest clicks' },
      { class: 'col-lg-1', name: 'user clicks' },
      { class: 'col-lg-1', name: 'rating' },
      { class: 'col-lg-1', name: 'is_public' },
      { class: 'col-lg-2', name: 'included in import' }
    ];

    public filters: SearchBookmakersParams = {
      query: '',
      is_public: 1,
      is_supported: 0,
      per_page: 12,
      page: 1,
      with: ['image']
    };
    public tags: string[] = [];

    created() {
      this.search({});
    }

    public search(filter: SearchBookmakersParams) {
      if (!filter.page) {
        filter.page = 1;
      }

      this.$store.dispatch(FETCH_BOOKMAKERS, Object.assign(this.filters, filter));
    }

    public getImageLink(bookmaker: Bookmaker): string {
      return get(bookmaker, 'image.link');
    }
  }
